import { ItemCategoriesMultiAutoComplete } from "@/features/ui/multiAutoComplete/itemCategory/itemCategory-multiAutoComplete";
import { FormTypeProps, FormValues } from "./CustomFilterForm";

import { AbstractItemsMultiAutoComplete } from "@/features/ui/multiAutoComplete/abstractItem/abstractItem-multiAutoComplete";
import { EquipmentsMultiAutoComplete } from "@/features/ui/multiAutoComplete/equipment/equipment-multiAutoComplete";
import { ItemsMultiAutoComplete } from "@/features/ui/multiAutoComplete/item/item-multiAutoComplete";
import { ItemTypesMultiAutoComplete } from "@/features/ui/multiAutoComplete/itemType/itemType-multiAutoComplete";
import { LocationsMultiAutoComplete } from "@/features/ui/multiAutoComplete/location/location-multiAutoComplete";
import { MoldsMultiAutoComplete } from "@/features/ui/multiAutoComplete/mold/mold-multiAutoComplete";
import { OperationsMultiAutoComplete } from "@/features/ui/multiAutoComplete/operation/operation-multiAutoComplete";
import { RoutingsMultiAutoComplete } from "@/features/ui/multiAutoComplete/routing/routing-multiAutoComplete";
import { RoutingTypesMultiAutoComplete } from "@/features/ui/multiAutoComplete/routingType/routingType-multiAutoComplete";
import { SitesMultiAutoComplete } from "@/features/ui/multiAutoComplete/site/site-multiAutoComplete";
import { UsersMultiAutoComplete } from "@/features/ui/multiAutoComplete/user/user-multiAutoComplete";
import styled from "@emotion/styled";
import { MultiSelect } from "@mantine/core";
import { UseFormReturnType } from '@mantine/form';
import { useTranslation } from "react-i18next";

interface SampleProps {
    formValue: UseFormReturnType<FormTypeProps>;
    multiField: string;
}

export const MultiField: React.FC<SampleProps> = ({ formValue, multiField }) => {

    let multiFieldName: keyof FormValues = multiField as keyof FormValues;

    const { i18n } = useTranslation();
    const t = i18n.getFixedT('en'); // 영어로 번역하는 함수를 가져옵니다.


    const onChangeHandler = (selectedItems: string[]) => {

        if (!selectedItems.length) {
            // formValue의 itemCategoryName 필드 업데이트
            formValue.setFieldValue(multiFieldName, []);
            return;
        }
        // 현재 formValue에서 선택된 항목들을 가져옴
        let currentItems = formValue.values[multiFieldName] || [];

        // 새로운 배열을 생성하지 않고, 선택된 항목들을 기존 배열에 추가
        const updatedSelectedItems = [...currentItems];

        selectedItems.forEach((item) => {
            if (!currentItems.includes(item)) {
                updatedSelectedItems.push(item); // 배열에 없는 경우에만 추가
            }
        });

        // formValue의 itemCategoryName 필드 업데이트
        formValue.setFieldValue(multiFieldName, updatedSelectedItems.map((item) => t(item)));
    }

    const renderComponent = (fieldName: keyof FormTypeProps) => {
        switch (fieldName) {
            // 다른 필드에 대한 컴포넌트를 조건부로 렌더링할 경우, 여기에 추가
            case 'abstractItemCode':
                return (
                    <AbstractItemsMultiAutoComplete
                        {...formValue.getInputProps(fieldName)}
                        onChange={onChangeHandler}
                    />
                );
            case 'equipmentCode':
                return (
                    <EquipmentsMultiAutoComplete
                        {...formValue.getInputProps(fieldName)}
                        onChange={onChangeHandler}
                    />
                );
            case 'itemCode':
                return (
                    <ItemsMultiAutoComplete
                        {...formValue.getInputProps(fieldName)}
                        onChange={onChangeHandler}
                    />
                );
            case 'itemCategoryName':
                return (
                    <ItemCategoriesMultiAutoComplete
                        {...formValue.getInputProps(fieldName)}
                        onChange={onChangeHandler}
                    />
                );
            case 'locationCode':
            case 'fromLocationCode':
            case 'toLocationCode':
                return (
                    <LocationsMultiAutoComplete
                        {...formValue.getInputProps(fieldName)}
                        onChange={onChangeHandler}
                    />
                );
            case 'moldCode':
                return (
                    <MoldsMultiAutoComplete
                        {...formValue.getInputProps(fieldName)}
                        onChange={onChangeHandler}
                    />
                );
            case 'routingCode':
                return (
                    <RoutingsMultiAutoComplete
                        {...formValue.getInputProps(fieldName)}
                        onChange={onChangeHandler}
                    />
                );
            case 'operationCode':
                return (
                    <OperationsMultiAutoComplete
                        {...formValue.getInputProps(fieldName)}
                        onChange={onChangeHandler}
                    />
                );
            case 'siteCode':
                return (
                    <SitesMultiAutoComplete
                        {...formValue.getInputProps(fieldName)}
                        onChange={onChangeHandler}
                    />
                );
            case 'userCode':
                return (
                    <UsersMultiAutoComplete
                        {...formValue.getInputProps(fieldName)}
                        onChange={onChangeHandler}
                    />
                );
            case 'itemType':
                return (
                    <ItemTypesMultiAutoComplete
                        {...formValue.getInputProps(fieldName)}
                        onChange={onChangeHandler}
                    />
                );
            case 'routingType':
                return (
                    <RoutingTypesMultiAutoComplete
                        {...formValue.getInputProps(fieldName)}
                        onChange={onChangeHandler}
                    />
                );
            default:
                return null;
        }
    };

    return renderComponent(multiFieldName);
};

export const FKMultiSelect = styled(MultiSelect)`
width: 70%;
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
`

export const FKInfoLabel = styled.div`
font-size: 12px;
color: #666666;
// padding-right: 8px;
padding-left: 0.4rem;
display: flex;
`;